.footer {
    display: flex;
    font-size: 0.7em;
    align-items: baseline;
}

.footer .mfb-project-01 img {
    width: 16px !important;
    height: 16px !important;
}

.nav {
    list-style: none;
}